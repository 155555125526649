import React from 'react';

function Home() {
  return (
    <div className="home">
      <h2>Welcome to Arian's Blog!</h2>
      <p>Join me on my journey as I explore technology, web development, and open-source projects.</p>
    </div>
  );
}

export default Home;
