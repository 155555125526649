import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Ensure this is imported

const firebaseConfig = {
  apiKey: "AIzaSyDonBbesdZxdl_pyHOPicMDUX2HpKewF-g",
  authDomain: "ariankazemi-656c6.firebaseapp.com",
  databaseURL: "https://ariankazemi-656c6-default-rtdb.firebaseio.com",
  projectId: "ariankazemi-656c6",
  storageBucket: "ariankazemi-656c6.appspot.com",
  messagingSenderId: "261053906435",
  appId: "1:261053906435:web:9a16da23ef174b289a9836",
  measurementId: "G-R8E8REB7FQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore and export it
const firestore = getFirestore(app);

export { firestore };
