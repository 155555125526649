import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from './firebase';

function Blog() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const postsCollection = collection(firestore, 'posts');
      const postSnapshot = await getDocs(postsCollection);
      setPosts(postSnapshot.docs.map(doc => doc.data()));
    };
    fetchPosts();
  }, []);

  return (
    <div className="blog">
      <h2>Blog Posts</h2>
      {posts.length > 0 ? (
        posts.map((post, index) => (
          <div className="blog-post" key={index}>
            <h3>{post.title}</h3>
            <p>{post.content}</p>
          </div>
        ))
      ) : (
        <p>No posts available.</p>
      )}
    </div>
  );
}

export default Blog;
