import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './home';
import About from './About';
import Blog from './Blog';
import SPO600 from './SPO600'; // Import the new SPO600 component
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Navbar */}
        <nav className="navbar">
          <h1>Arian's Blog</h1>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/blog">Blog Posts</Link></li>
            <li><Link to="/spo600">SPO600 Posts</Link></li> {/* New link for SPO600 */}
          </ul>
        </nav>

        {/* Define Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/spo600" element={<SPO600 />} /> {/* New route for SPO600 */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
